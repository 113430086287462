import './App.css';
import wiz from './wiz_wanted2.png'
import peetle from './peetle_wanted2.png'
import dvd from './dvd_wanted2.png'
import gunshot from './gunshot.mp3'
import cock from './guncocking.mp3'
import applesBacking from './applesbacking.png'
import logo from "./Logo.png"
import figure from "./figure.png"
import { useState } from 'react';
import scam from "./scamalert.png";
import proof from "./rug.png";
import trash from "./trash.png"
function App() {
  const gunshotAudio = new Audio(gunshot);
  const cockAudio = new Audio(cock);
  const [audioToPlay, setAudioToPlay]= useState(0);
  const [headShotCounter, setHeadShotCounter] = useState(0);
  const map = [cockAudio, gunshotAudio]
  return (
    <div className="App">
      <div class="header">
        <div class="title-wrap"> 
          <div class="title"> OFFICIAL ANNOUNCMENT</div>
          <img src={logo}></img>
        </div>

        <div class="release"> UNCLASSIFIED//FOR INFORMING THE PUBLIC OF IMMINENT RISK</div>
      </div>

      <div class="header-footer">
        <span class="contact"> contact info:: 770-765-5928 // RugWatchNetwork@gmail</span>
        <span class="risk">RISK LEVEL: HIGH</span>
      </div>
      <div class="top-info">
        <div class="title">Public Awareness Announcement</div>
        <div class="subtitle">Possible Sweetango R.U.G.</div>
        <span class="date"> Janauary 23rd, 2022</span>
      </div>
      <img class="scam" src={scam}></img>
      <div class="scammer-title">
        <div>
          Sweetango NFT Scammers
        </div>
        {headShotCounter > 0 && (
          <div class="counter">
            Headshot Counter: {headShotCounter}
          </div>
        )}
      </div>
      <div class="mugshots">
        <div class="mug">
          <img altText="scammer 1" onClick={()=>{map[audioToPlay].play(); setAudioToPlay(!!audioToPlay ? 0 : 1); setHeadShotCounter(headShotCounter+audioToPlay)}} src={wiz}/>
          <div class="name">@CrummyWiz</div>
        </div>
        <div class="mug">
          <img altText="scammer 1" onClick={()=>{map[audioToPlay].play(); setAudioToPlay(!!audioToPlay ? 0 : 1); setHeadShotCounter(headShotCounter+audioToPlay)}} src={dvd}/>
          <div class="name">@dvd</div>
        </div>
        <div class="mug">
          <img altText="scammer 1" onClick={()=>{map[audioToPlay].play(); setAudioToPlay(!!audioToPlay ? 0 : 1); setHeadShotCounter(headShotCounter+audioToPlay)}} src={peetle}/>
          <div class="name">@peetle</div>
        </div>
      </div>
      <div>
        Pictured above are the relentless shillers responsible for the scam
      </div>
    <div class="important">
      IMPORTANT INFORMATION:
    </div>
    <div class="info">
      <a href='www.sweetango.zone'>www.SweeTango.zone</a> is an NFT project launched on the Fantom blockchain (play at your own risk). The developers (pictured above) purport the game to be a family friendly “NFT Pet simulator”.. but is it? As of Jan 22, 2022 the game’s only feature is a bleak room in which you can torture your virtual pet known as a SweeTango*. If that isn’t telling enough, then read on for more evidence that SweeTango.zone is a likely scam.
    </div>
    <div class="sub-info">*Copyrighted name for a genetically modified apple, already trademarked by the University of Minnesota</div>
    <img class="figure" src={figure}/>
    <div class="info"><a href='https://ftmscan.com/token/0x9e4254df23488a4E964944776d9FFEeBa968F4d3'>Apples (Ticker: $APLZ)</a> are the in-game currency of SweeTango.zone, not only are they completely useless, but the developers also make some dubious claims about them. According to the <a href='https://twitter.com/sweetango_zone'>SweeTango official twitter</a> $APLZ are backed 1 to 1 by real apples, but through some cursory investigation we can see that this is a <strong>complete fabrication</strong>. Only about 30% of the supply is backed by real apples, or “Apple Equivalents”, whatever those could be.</div>
    <img class="apples-backing" src={applesBacking}></img>
    <div class="info">At Rug Watch Network, we’re known for our boots on the ground approach to exposing cryptocurrency scams. One of our reporters was able to infiltrate the developers private chatroom and record this damning message. We’ll leave it to the reader to interpret this.</div>
    <img class="apples-backing" src={trash}></img>
    <div class="info">If this isn’t proof enough then lets dive into the code. There’s multiple references to the word “R.U.G.”. You can see for yourself as the developers brazenly published them to test net, already assured of their victory. 
    </div>
    <img class="proof" src={proof}></img>
    <div class="info">As we always say at Rug Watch Network: <strong>“The code never lies”</strong>, as evidenced by this likely-scam of a project.</div>
    <div class="bye">RWN signing off.</div>
    <div class="footer">  
      SWEETANGO NFTS are a scam, RIGHT CLICK AND SAVE THIS WEBSITE IF U AGREE
    </div>
    </div>
  );
}

export default App;
